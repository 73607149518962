import React, { MemoExoticComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CommonButton from 'common/components/entities/Button/Button'
import { BadRequest, InternalError, NetworkError } from 'common/errors'
import { buyUpsellOfferCard } from 'publisher/api/mercadoPagoApi'
import FieldErrors from 'publisher/components/FieldErrors'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import { useFinalDigitalProductPrice } from '../../../context/shared/hooks/useFinalDigitalProductPrice'
import { useFinalPhysicalProductPrice } from '../../../context/shared/hooks/useFinalPhysicalProductPrice'
import { PageId } from '../../../store/page/PageStateInterface'
import { PurchaseProcessId } from '../../../store/payment/PaymentStateInterface'
import useUpsellSubmit from '../hooks/useUpsellSubmit'
import { UpsellSubmitBodyInterface } from '../types/UpsellSubmitBodyInterface'
import { UpsellPaymentButtonPropsType } from './UpsellPaymentButton'

function MercadoPagoUpsellButton({ entity }: UpsellPaymentButtonPropsType) {
  const [MercadoPagoCardPayment, setMercadoPagoCardPayment] =
    // @ts-ignore
    useState<MemoExoticComponent<any>>(null)
  const digitalProductPrice = useFinalDigitalProductPrice()
  const physicalProductPrice = useFinalPhysicalProductPrice()
  const { t } = useTranslation(['publisher'])
  const paymentAmount = digitalProductPrice || physicalProductPrice

  const { errors, setErrors, isLoading, submit } = useUpsellSubmit(entity)

  const loadCodeEditor = async () => {
    const { default: lazyComponent } = await import(
      /* webpackChunkName: "mercado-pago-card-payment" */ 'publisher/paymentProcessors/mercadoPago/components/MercadoPagoCardPayment'
    )
    setMercadoPagoCardPayment(() => lazyComponent)
  }

  useEffect(() => {
    ;(async function () {
      await loadCodeEditor()
    })()
  }, [])

  const handleSubmit = async () => {
    await submit(
      async (
        body: UpsellSubmitBodyInterface,
        pageId: PageId,
        purchaseProcessId: PurchaseProcessId,
      ) => {
        try {
          const cardFormData =
            await window.cardPaymentBrickController.getFormData()

          const { data } = await buyUpsellOfferCard(pageId, purchaseProcessId, {
            upsell_payment_form: {
              token: cardFormData.token,
              mercadoPagoPaymentMethod: cardFormData.payment_method_id,
              installments: cardFormData.installments,
              ...body,
            },
          })

          window.location.assign(data.redirect)
        } catch (error) {
          if (error instanceof BadRequest) {
            setErrors(error.response.data.errors.common)
          } else if (error instanceof NetworkError) {
            setErrors([t('core.errors.no_connection')])
          } else if (error instanceof InternalError) {
            setErrors([t('core.error.title')])
          } else {
            console.log('error', error)
          }
        }
      },
    )
  }

  return (
    <div>
      {MercadoPagoCardPayment && (
        <MercadoPagoCardPayment amount={paymentAmount} />
      )}
      <StyleWrapper
        margin={entity.margin}
        mobileMargin={entity.mobileMargin}
        appearance={entity.appearance}
        flex
      >
        <CommonButton
          onClick={handleSubmit}
          disabled={isLoading || !paymentAmount}
          text={entity.text}
          border={entity.border}
          mobileBorder={entity.mobileBorder}
          subText={entity.subText}
          padding={entity.padding}
          mobilePadding={entity.mobilePadding}
          alignSelf={entity.alignSelf}
          backgroundColor={entity.backgroundColor}
          mobileBackgroundColor={entity.mobileBackgroundColor}
          attrId={entity.htmlAttrId}
          width={entity.width}
          mobileWidth={entity.mobileWidth}
          textColor={entity.textColor}
          subTextColor={entity.subTextColor}
          mobileTextColor={entity.mobileTextColor}
          mobileSubTextColor={entity.mobileSubTextColor}
          textFontSize={entity.textFontSize}
          mobileTextFontSize={entity.mobileTextFontSize}
          subTextFontSize={entity.subTextFontSize}
          mobileSubTextFontSize={entity.mobileSubTextFontSize}
          textFontFamily={entity.textFontFamily}
          textFontWeight={entity.textFontWeight}
          textFontStyle={entity.textFontStyle}
          subTextFontFamily={entity.subTextFontFamily}
          subTextFontWeight={entity.subTextFontWeight}
          subTextFontStyle={entity.subTextFontStyle}
          mobileTextFontFamily={entity.mobileTextFontFamily}
          mobileTextFontWeight={entity.mobileTextFontWeight}
          mobileTextFontStyle={entity.mobileTextFontStyle}
          mobileSubTextFontFamily={entity.mobileSubTextFontFamily}
          mobileSubTextFontWeight={entity.mobileSubTextFontWeight}
          mobileSubTextFontStyle={entity.mobileSubTextFontStyle}
          boxShadow={entity.boxShadow}
          mobileBoxShadow={entity.mobileBoxShadow}
          lineHeight={entity.lineHeight}
          mobileLineHeight={entity.mobileLineHeight}
          iconClassNameBefore={entity.iconClassNameBefore}
          iconClassNameAfter={entity.iconClassNameAfter}
          hover={entity.hover}
          mobileHover={entity.mobileHover}
        />
      </StyleWrapper>
      <FieldErrors errors={errors} align="center" />
    </div>
  )
}

export default MercadoPagoUpsellButton
