import PropTypes from 'prop-types'
import React from 'react'
import { wrapFontFamily } from 'common/components/entities/Text/utils'
import TriggeredPopup from 'publisher/pages/components/TriggeredPopup'
import { usePage } from 'publisher/store'
import pageSelectors from 'publisher/store/page/pageSelectors'
import Meta from '../../components/core/Meta'
import OnExitPopup from '../components/OnExitPopup'
import OnloadPopups from '../components/OnloadPopups'
import PageUi from '../ui/PageUi'

function UpsellPage({ children }) {
  const globalTextFontProperties = usePage(
    pageSelectors.getGlobalFontProperties,
  )
  return (
    <PageUi
      textFontFamily={wrapFontFamily(globalTextFontProperties.fontFamily)}
      textFontWeight={globalTextFontProperties.fontWeight}
      textFontStyle={globalTextFontProperties.fontStyle}
    >
      <Meta />
      {children}
      <TriggeredPopup />
      <OnExitPopup />
      <OnloadPopups />
    </PageUi>
  )
}

UpsellPage.propTypes = {
  children: PropTypes.node.isRequired,
}

export default UpsellPage
