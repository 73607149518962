import axios from 'axios'
import { UpsellPaymentFormInterface } from '../paymentProcessors/mercadoPago/types/UpsellPaymentFormInterface'
import { PageId } from '../store/page/PageStateInterface'
import { PurchaseProcessId } from '../store/payment/PaymentStateInterface'

export function buyMainOffer(
  pageId: PageId,
  purchaseProcessId: PurchaseProcessId,
  body: any,
) {
  return axios.post(
    `/secure/mercado-pago/${pageId}/buy-main-offer/${purchaseProcessId}`,
    body,
  )
}

export function buyUpsellOfferCard(
  pageId: PageId,
  purchaseProcessId: PurchaseProcessId,
  payload: UpsellPaymentFormInterface,
) {
  return axios.post(
    `/secure/mercado-pago/${pageId}/buy-upsell-offer-card/${purchaseProcessId}`,
    payload,
  )
}
